* {
  font-family: 'Poppins', 'Amiri', 'sans-serif';
}

.MuiButton-root {
  font-family: 'Poppins', 'Amiri', sans-serif !important;
}

body {
  margin: 0;
}